<template>
	<div> 
		<Report />
	</div>
</template>

<script>
// @ is an alias to /src
import Report from '@/components/reports/transactionPayroll/TransactionPayroll.vue'

export default {
	name: 'ReportByTransaction',
	components: {
		Report,
	},
	data() {
		return {
		
		}
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
		
	},
}
</script>
